import { RouteComponentProps } from '@reach/router';
import { FC, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { IEmbedConfiguration, models } from 'powerbi-client';
import { useTenantCompany } from '../../hooks';
import ReportDataQueries from '../../graphql/Queries/ReportDataQueries';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

const { GET_REPORT_DATA } = ReportDataQueries;

const PBReportLayout: FC<RouteComponentProps> = (RouteComponentProps) => {
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [reportPage, setReportPage] = useState('');
  const { tenantName } = useTenantCompany();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getReportData, { data }] = useLazyQuery(GET_REPORT_DATA, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const {
        reportData: {
          reportData: { accessToken, embedUrl, reportPage },
        },
      } = data;
      setEmbedUrl(embedUrl);
      setAccessToken(accessToken);
      setReportPage(reportPage);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (tenantName) {
      getReportData({ variables: { tenantName } });
    }
  }, [tenantName]);

  if (loading) {
    return (
      <Box p="5rem 4rem 0 4rem">
        <div>Loading...</div>
      </Box>
    );
  }

  const reportConfig: IEmbedConfiguration = {
    type: 'report',
    embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken,
    pageName: reportPage,
    settings: {
      filterPaneEnabled: false,
      persistentFiltersEnabled: true,
      navContentPaneEnabled: true,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
    },
  };

  return (
    <Box p="5rem 4rem 0 4rem">
      <PowerBIEmbed embedConfig={reportConfig} cssClassName="powerbi-report" />
    </Box>
  );
};
export default PBReportLayout;
