import { Box, IconButton } from '@chakra-ui/react';
import { Image } from '@chakra-ui/image';
import { navigate } from '@reach/router';

const SmallMenu = (props: { setMenuOpen: (val: boolean) => void; open: boolean }) => {
  const { setMenuOpen, open } = props;

  return (
    <Box
      bg="linear-gradient(360deg, rgba(249, 254, 251, 0) 0%, rgba(249, 254, 251, 0.8) 100%)"
      height="100%"
      w="100%"
      {...(!open && { maxWidth: '48px' })}
    >
      <IconButton
        variant="simple"
        icon={<Image w="11px" src="/icons/stimulus_short.svg" alt="stimulus_logo" />}
        aria-label="home"
        onClick={() => navigate('/dashboard')}
      />
      <IconButton
        variant="simple"
        icon={<Image w="17px" src="/icons/dashboard_icon.png" alt="dashboard_logo" />}
        aria-label="companies"
        onClick={() => {
          navigate('/dashboard');
          return setMenuOpen(true);
        }}
      />
      <IconButton
        variant="simple"
        icon={<Image w="17px" src="/icons/companies_icon.png" alt="companies_logo" />}
        aria-label="companies"
        onClick={() => {
          navigate('/companies/all/list/1');
          return setMenuOpen(true);
        }}
      />
      <IconButton
        variant="simple"
        icon={<Image w="17px" src="/icons/project_icon.png" alt="projects_logo" />}
        aria-label="projects"
        onClick={() => {
          navigate('/projects/1');
          return setMenuOpen(true);
        }}
      />
      <IconButton
        variant="simple"
        icon={<Image w="17px" src="/icons/reports_icon.png" alt="reports_logo" />}
        aria-label="reports"
        onClick={() => {
          navigate('/pbreport');
          return setMenuOpen(true);
        }}
      />
    </Box>
  );
};

export default SmallMenu;
